<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛况管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button icon="el-icon-back" type="text" title="返回" class="back" @click="backPage()"></el-button>
    <div class="tab-area">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane name="first">
          <template #label>
            <div class="my-label">事件登记</div>
          </template>
          <div class="add_btn">
            <div>
              <el-button type="info" v-if="this.status == true">比赛已结束</el-button>
              <el-button type="warning" @click="finishMatch" v-else>结束比赛</el-button>
            </div>
            <el-button style="margin-left: 20px;" type="primary" @click="addRecord">新增</el-button>
          </div>
          <el-table :data="tableData" style="width: 100%;margin-top:20px">
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column label="时间（分钟）" prop="minute" align="center"></el-table-column>
            <el-table-column label="球队名" prop="teamName" align="center"></el-table-column>
            <el-table-column label="球员" prop="playerName" align="center"></el-table-column>
            <el-table-column label="事件" prop="recordType" align="center">
              <template slot-scope="scope">
                <option v-if="scope.row.recordType == 1">进球</option>
                <option v-if="scope.row.recordType == 2">助攻</option>
                <option v-if="scope.row.recordType == 4">红牌</option>
                <option v-if="scope.row.recordType == 3">黄牌</option>
                <option v-if="scope.row.recordType == 5">乌龙球</option>
                <option v-if="scope.row.recordType == 6">换上</option>
                <option v-if="scope.row.recordType == 7">换下</option>
              </template>
            </el-table-column>
            <!-- <el-table-column label="积分" prop="score"></el-table-column> -->
            <el-table-column label="操作" prop="event" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="editCourse(scope.row)">修改</el-button>
                <el-button type="text" @click="deleteOuts(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="background-color: #fff; margin-top: 10px; padding: 10px; text-align: right">
            <el-pagination background layout="sizes, prev, pager, next, jumper" :current-page="pageInfo.page"
              :page-sizes="[10, 20, 40, 100]" :page-size="pageInfo.pageSize" :total="pageInfo.count"
              @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <template #label>
            <div class="my-label">比赛直播</div>
          </template>
          <div class="add_btn">
            <el-button type="primary" @click="addResource">新增</el-button>
          </div>
          <el-table :data="resourceData" style="width: 100%;margin-top:20px">
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column label="直播类型" align="center">
              <template slot-scope="scope">
                <option v-if="scope.row.resourceType == 1">微信视频号</option>
                <option v-if="scope.row.resourceType == 2">Bilibili</option>
                <option v-if="scope.row.resourceType == 3">抖音</option>
                <option v-if="scope.row.resourceType == 4">比赛录像</option>
                <option v-if="scope.row.resourceType == 5">集锦</option>
              </template>
            </el-table-column>
            <el-table-column label="地址" prop="url" align="center"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" align="center"></el-table-column>
            <el-table-column label="操作" prop="event" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="editResource(scope.row)">修改</el-button>
                <el-button type="text" @click="deleteResource(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="dialogVisibleRecord" :title="title" width="60%" @close="closeDialog">
      <el-form ref="form" :model="form" label-width="100px" class="infoForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="时间" prop="minute">
              <el-select v-model="form.minute" placeholder="请选择时间">
                <el-option v-for="item in numList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <!-- <el-input v-model="form.minute" placeholder="请输入时间"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="球队" prop="teamName">
              <el-select v-model="form.teamName" placeholder="请选择球队" @change="changeTeam">
                <el-option v-for="item in teamList" :key="item.teamId" :label="item.teamName" :value="item.teamName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="球员" prop="playerName">
              <el-select v-model="form.playerName" placeholder="请选择球员" @change="changePlayer">
                <el-option key="0" label="" value="" disabled>
                  <span style="float: left">球衣号</span>
                  <span style="float: right; font-size: 13px">球员</span>
                </el-option>
                <el-option v-for="item in playerList" :key="item.playerId"
                  :label="`${item.playerName}(${item.playerNumber})`" :value="item.playerName">
                  <span style="float: left">{{ item.playerNumber }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.playerName }}</span>
                </el-option>
                <!-- <el-option v-for="item in playerList" :key="item.playerId" :label="item.playerName"
                  :value="item.playerName">
                </el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件" prop="recordType">
              <el-select v-model="form.recordType" placeholder="请选择事件">
                <el-option v-for="item in event" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveCourse">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleResource" :title="ResourceTitle" width="60%" @close="closeResource">
      <el-form ref="resourceForm" :model="resourceForm" label-width="100px" class="infoForm" :rules="rules">
        <el-form-item label="直播类型" prop="resourceType">
          <el-select v-model="resourceForm.resourceType" placeholder="请选择类型">
            <el-option v-for="item in resourceType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源地址" prop="url">
          <el-input placeholder="请输入资源地址" v-model="resourceForm.url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleResource = false">取 消</el-button>
        <el-button type="primary" @click="saveResource">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      matchStatus: '',
      status: false,
      tableData: [],
      event: [{
        value: 1,
        label: "进球"
      },
      {
        value: 2,
        label: "助攻"
      },
      {
        value: 3,
        label: "黄牌"
      },
      {
        value: 4,
        label: "红牌"
      },
      {
        value: 5,
        label: "乌龙球"
      },
      {
        value: 6,
        label: "换上"
      },
      {
        value: 7,
        label: "换下"
      },
      ],
      teamList: [
        { teamName: '', teamId: '' },
        { teamName: '', teamId: '' },
      ],
      pageInfo: {
        page: 1,
        pageSize: 10,
        count: 0,
      },
      activeName: "first",
      form: {},
      playerList: [],
      matchInfo: {},
      matchList: [],
      numList: [],
      title: "",
      ResourceTitle: "",
      rules: {
        minute: [{ required: true, message: '请选择时间', trigger: 'change' }],
        teamName: [{ required: true, message: '请选择球队', trigger: 'change' }],
        playerName: [{ required: true, message: '请选择球员', trigger: 'change' }],
        recordType: [{ required: true, message: '请选择事件', trigger: 'change' }],
        resourceType: [{ required: true, message: '请选择资源类型', trigger: 'change' }],
        url: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      },
      resourceType: [
        {
          value: 1,
          label: "微信视频号"
        },
        {
          value: 2,
          label: "Bilibili"
        },
        {
          value: 4,
          label: "比赛录像"
        },
        {
          value: 5,
          label: "集锦"
        },
        {
          value: 3,
          label: "抖音"
        }
      ],
      resourceData: [],
      resourceForm: {},
      dialogVisibleRecord: false,
      dialogVisibleResource: false
    }
  },
  mounted() {
    this.matchInfo.matchId = this.$route.query.matchId;
    this.matchStatus = this.$route.query.status;
    if (this.matchStatus == 3) {
      this.status = true
    } else {
      this.status = false
    }
    this.getMatchCourse();
    this.getMinute();
    this.getTeamList();
    this.getResource()
  },
  methods: {
    // 获取赛况列表
    async getMatchCourse() {
      const params = {
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
      };
      const { res } = await this.$http.get(`/Match/MatchScheduleRecordList/${this.matchInfo.matchId}/${this.$route.query.matchScheduleId}`, { params });
      this.tableData = res.data.list || [];
    },
    // 获取录像列表
    async getResource() {
      const { res } = await this.$http.get(`/UserTeam/LiveResourceList/${this.matchInfo.matchId}/${this.$route.query.matchScheduleId}`);
      this.resourceData = res.data || [];
    },
    // 头部组件切换比赛，加载接口数据
    changeMatch(match) {
      this.matchInfo = match;
      this.getMatchCourse()
    },
    // 获取球队
    async getTeamList() {
      this.teamList[0].teamName = this.$route.query.homeTeamName
      this.teamList[0].teamId = this.$route.query.homeTeamId
      this.teamList[1].teamName = this.$route.query.awayTeamName
      this.teamList[1].teamId = this.$route.query.awayTeamId
    },
    // 选择球队获取球员
    changeTeam(val) {
      this.form.teamId = this.teamList.find(teamList => {
        return teamList.teamName === val;
      }).teamId;
      console.log(1111, val)
      this.getPlayerList()
    },
    // 获取球员
    async getPlayerList() {
      const { res } = await this.$http.get(`/Match/TeamPlayerList/${this.form.teamId}`);
      this.playerList = res.data || [];
    },
    // 选择球员
    changePlayer(val) {
      this.form.playerId = this.playerList.find(playerList => {
        return playerList.playerName === val;
      }).playerId;
    },
    // 选择时间
    getMinute() {
      for (let i = 1; i <= 90; i++) {
        this.numList.push(i);
      }
    },
    // 保存赛况
    saveCourse() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.matchScheduleId = this.$route.query.matchScheduleId
          this.form.matchId = this.matchInfo.matchId
          this.$confirm('请核实信息确定要发布该赛况吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { res } = await this.$http.post('/Match/SaveScheduleRecord', this.form);
            if (res.isSuccess) {
              this.$showSuccess('发布成功');
              this.dialogVisibleRecord = false;
              this.getMatchCourse();
            }
          });
        } else {
          this.$message.error("请填写完整信息！");
          return
        }
      })
    },
    // 删除赛况
    deleteOuts(row) {
      this.$confirm('请核实信息确定删除该赛况吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post(`/Match/DeleteMatchScheduleRecord/${this.matchInfo.matchId}/${row.recordId}`);
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.dialogVisibleRecord = false;
          this.getMatchCourse();
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getMatchCourse();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.getMatchCourse();
    },
    // 结束比赛
    finishMatch() {
      this.$confirm('确定要结束该场比赛吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post(`/Match/FinishSchedule/${this.matchInfo.matchId}/${this.$route.query.matchScheduleId}`);
        if (res.isSuccess) {
          this.$showSuccess('比赛结束');
          this.status = true
        }
      });
    },
    // 返回上一页
    backPage() {
      this.$router.back(-1)
    },
    // 新增赛况
    addRecord() {
      this.dialogVisibleRecord = true;
      this.title = "新增赛况"
    },
    // 修改赛况
    editCourse(row) {
      this.dialogVisibleRecord = true;
      this.title = "修改赛况";
      this.form = row;
    },
    closeDialog() {
      this.dialogVisibleRecord = false;
      this.title = '';
      this.form = {};
    },
    //新增直播资源
    addResource() {
      this.ResourceTitle = "新增资源";
      this.dialogVisibleResource = true;
    },
    //修改直播资源
    editResource(row) {
      this.dialogVisibleResource = true;
      this.ResourceTitle = "修改资源";
      this.resourceForm = row
    },
    // 保存直播资源
    saveResource() {
      this.$refs.resourceForm.validate((valid) => {
        if (valid) {
          this.resourceForm.scheduleId = this.$route.query.matchScheduleId
          this.resourceForm.matchId = this.matchInfo.matchId
          this.$confirm('请核实信息确定要发布该直播资源吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { res } = await this.$http.post('/UserTeam/SaveLiveResource', this.resourceForm);
            if (res.isSuccess) {
              this.$showSuccess('发布成功');
              this.dialogVisibleResource = false;
              this.getResource();
            }
          });
        } else {
          this.$message.error("请填写完整信息！");
          return
        }
      })
    },
    // 关闭弹窗
    closeResource() {
      this.dialogVisibleResource = false;
      this.ResourceTitle = '';
      this.resourceForm = {};
    },
    //删除直播资源
    deleteResource(row) {
      this.$confirm('确定要删除该资源吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post(`/UserTeam/DeleteLiveResource/${row.resourceId}`);
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.dialogVisibleResource = false;
          this.getResource();
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.back {
  margin-top: 10px;
  font-size: 18px;
}

.league-match {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  background: linear-gradient(#7ECB2B, #4ebbd6);
  padding: 20px;
  border-radius: 20px;

  .leagueImage {
    width: 65px;
    height: 65px;
  }

  .leagueInfo {
    display: flex;
    align-items: center;
  }

  .teamInfo {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  .lengueName {
    font-size: 23px;
  }

  .application {
    margin-top: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .teamNum {
    color: #2e10db;
  }

  .feeType {
    margin-left: 20px;
    color: rgb(224, 205, 120);
  }

}

.tab-area {
  margin-top: 10px;
}

.notOpen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.add_btn {
  display: flex;
  justify-content: flex-end;
}
</style>
  
